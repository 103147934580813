import React, { useEffect, useState } from "react"
import Layout from "../../components/shared-layout";
import { Alert, Box, Checkbox, CircularProgress, Divider, FormControlLabel, Grow, IconButton, Skeleton, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// @ts-ignore
import SnowMountain from "../images/snow-mountain.jpg";
import { ITEM_PERFECT_INLINED, PADDING_HORIZONTAL, THEME_YELLOW } from "../../constants/style";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VmButton from "../../components/shared-button";
import useStores from "../../hooks/use-stores";
import { navigate } from "gatsby";
import { FilterType, Language, ListLayoutType, OrderStatus, PaymentType, ProfileSectionType, SearchCategory } from "../../constants/options";
import SlideDown from "react-slidedown";
import 'react-slidedown/lib/slidedown.css';
import ProductCard from "../../components/product-card";
import ListAltIcon from '@mui/icons-material/ListAlt';
import { isWeb } from "../../utilities/platform";
import { CHECK_OUT_ID_KEYWORD, CHECK_OUT_PAYMENT_METHOD_KEYWORD } from "../../constants/settings";
import { IsAuthed } from "../../utilities/general";
import * as queryString from "query-string";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SellIcon from '@mui/icons-material/Sell';
import CancelIcon from '@mui/icons-material/Cancel';
import { GetCartItemNumAsGuest, RemoveItemFromCartAsGuest } from "../../utilities/cart-item";
import CheckOutStepper from "../../components/checkout-stepper";
import { IsEmptyStr } from "../../utilities/field-validation";

const ForgotPasswordPage = observer(({ location }: { location: any }) => {
  const { t }: any = useTranslation();
  const { rootStore, userStore } = useStores();
  const [orderStatus, setOrderStatus] = useState<OrderStatus>(OrderStatus.NOTIFICATION);
  const { verifyLink } = queryString.parse(location.search);
  const [response, setResponse] = useState<any>();

  useEffect(() => {
    if (!IsEmptyStr(verifyLink)) emailVerify();
    else navigate("/");
  }, []);

  const emailVerify = () => {
    userStore.emailVerify(`${verifyLink}`)
      .then(() => setResponse(true))
      .catch(() => setResponse(false));
  }

  return (
    <Layout pageName={t('EMAIL_VERIFICATION')}>
      <Box className={PADDING_HORIZONTAL}>
        {userStore.loading ? <Box className="text-center mt-20">
          <CircularProgress />
          <p className="mt-4">{t('VALIDATING_YOUR_EMAIL')}...</p>
        </Box>
          : response != null && <>
            {response ? <>
              <p className="text-center text-xl 2xl:text-2xl font-bold mt-20">{t('THANK_YOU_YOUR_EMAIL_VALIDATED')}</p>
              <p className="text-center text-base 2xl:text-lg mt-4">{t('NOW_U_CAN_LOGIN_TO_YOUR_ACCOUNT')}</p>
              <Box className="flex justify-center mt-8">
                <VmButton onClick={() => navigate("/")} className="">{t('GO_HOME')}</VmButton>
              </Box>
            </> : <p className="text-center text-xl 2xl:text-2xl font-bold mt-20">{t('FAILED_TO_VALIDATE_EMAIL_PLS_TRY_AGAIN')}</p>}
            <Box className="flex justify-center mt-8">
              <VmButton onClick={() => navigate("/")} className="">{t('GO_HOME')}</VmButton>
              <VmButton onClick={emailVerify} className="ml-4">{t('TRY_AGAIN')}</VmButton>
            </Box>
          </>}
      </Box>
    </Layout>
  )
});

export default ForgotPasswordPage;
